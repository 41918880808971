import React from "react"
import styled, {ThemeProvider} from "styled-components";
import "../styles/anormalize.css";

// Components
import Nav from '../components/Nav';
import Seo from '../components/Seo';
import ContactCard from '../components/ContactCard';

// Constants
import Theme from '../constants/theme';
import Footer from "../components/Footer/Footer";

export default function Contact() {

  return (
    <ThemeProvider theme={Theme}>
      <Seo title="Contact" />
      <PageWrapper>
        <Nav />
        <CardsWrapper>
          <ContactCard />
          <Footer />
        </CardsWrapper>
      </PageWrapper>
    </ThemeProvider>
  )
}

const PageWrapper = styled.div`
  background-color: ${({theme}) => theme.color.background};
  display: flex;
  justify-content: center;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1600px;
  padding: 150px 30px 40px;
  box-sizing: border-box;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.sm}){
    padding: 150px 10px 40px;  
  }
`
