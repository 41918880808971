import React, { useRef, useState} from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";

// Components
import Card from '../Card';
import {Title, Paragraph, Link } from '../Typography';

// Assets
import ContactImage from '../../assets/contact.jpeg';

type ContactCardProps = {
    content: Content
}

type Content = {
    title: string
    copy: string
}

const ContactCard: React.FC<ContactCardProps> = (props) => {


    const { register, handleSubmit } = useForm();

    const [successMessage, setSuccessMessage ] = useState<string | undefined>()
    const [errorMessage, setErrorMessage ] = useState<string | undefined>()
    const [formLoading, setFormLoading ] = useState<boolean>(false)

    const onSubmit = (data) => {

        const formData = {
            ...data,
            "form-name": "contact"
        };

        setFormLoading(true);
        setSuccessMessage(undefined);
        setErrorMessage(undefined);


        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        })
        .then(() => {
            setSuccessMessage('Thank you for getting in contact, we will be in contact with you shortly.')
        })
        .catch((error) => {
            setErrorMessage("There was an issue processing this request, please try again later")
        })
        .finally(() => setFormLoading(false))

    };


    return (
        <CardWrapper>
            <ImageWrapper>
                <Image src={ContactImage} alt="3 rotary phones on a wall" />
            </ImageWrapper>
            <ContentWrapper>
                <Title>Get in Touch</Title>
                <Paragraph>If you want to have a general chat or discuss possible freelance opportunities, feel free to get in contact with the form below!</Paragraph>
                <Form name="contact" id="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
                    <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormInput {...register("email")} type="email" placeholder="Email Address" required/>
                    </FormItem>
                    <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormInput {...register("name")} type="text" placeholder="Your Name" required/>
                    </FormItem>
                    <FormItem>
                        <FormLabel>Message</FormLabel>
                        <FormTextArea {...register("message")} placeholder="Your Message" required/>
                    </FormItem>
                    <input type="hidden" name="bot-field" {...register("bot-field")} />

                    <Paragraph>By submitting the form you accept our <Link href="/privacy">Privacy Policy</Link>.</Paragraph>

                    {successMessage ? <SuccessMessage>{successMessage}</SuccessMessage> : null}
                    {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}

                    <FormSubmit type="submit" value="Submit" disabled={formLoading}/>
                </Form>
            </ContentWrapper>
        </CardWrapper>
    );
}

export default ContactCard;

const CardWrapper = styled(Card)`
    display: flex;
    flex-direction: row;l
    align-items: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        flex-direction: column-reverse;
    }
`

const ContentWrapper = styled.div`
    width: 50%;
    padding: 30px  100px 30px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}){
        padding: 20px  50px 20px 20px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        padding: 20px;
        width: 100%;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
    border-radius: 20px 0 0 20px;
    object-fit: cover;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        border-radius: 0 0 20px 20px;
    }
`;

const ImageWrapper = styled.div`
    width: 50%;
    background-color: ${({theme}) => theme.color.lightBlue};
    border-radius: 20px 0 0 20px;
    text-align: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        width: 100%;
        border-radius: 0 0 20px 20px;
    }
`

const Form = styled.form`
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const FormLabel = styled.label`
    font-family: futura-pt-bold, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
    margin: 0;
    text-decoration: none;
    color: ${({theme}) => theme.color.text};
`

const FormInput = styled.input`
    width: 100%;
    padding: 15px;
    border: solid 3px;
    color: ${({theme}) => theme.color.lightBlue};
    background-color: ${({theme}) => theme.color.blue};
    border-radius: 30px;
    font-family: futura-pt-bold, sans-serif;
    &::placeholder{
        color: ${({theme}) => theme.color.lightBlue};
    }
`

const FormTextArea = styled.textarea`
    width: 100%;
    padding: 15px;
    border: solid 3px;
    color: ${({theme}) => theme.color.lightBlue};
    background-color: ${({theme}) => theme.color.blue};
    border-radius: 30px;
    min-height: 100px;
    max-height: 100px;
    font-family: futura-pt-bold, sans-serif;
    &::placeholder{
        color: ${({theme}) => theme.color.lightBlue};
    }
`

const FormSubmit = styled.input`
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    color: ${({theme}) => theme.color.lightBlue};
    background-color: ${({theme}) => theme.color.blue};
    font-family: futura-pt-bold, sans-serif;
    border: solid 3px;
    border-color: ${({theme}) => theme.color.lightBlue};
    width: fit-content;
    padding 8px 16px;
    border-radius: 30px;
    text-decoration: none;
    &:hover{
        color: ${({theme}) => theme.color.blue};
        background-color: ${({theme}) => theme.color.lightBlue};
        border-color: ${({theme}) => theme.color.blue}
    }
`

const SuccessMessage = styled(Paragraph)`
    color: ${({theme}) => theme.color.green};
`
const ErrorMessage = styled(Paragraph)`
    color: ${({theme}) => theme.color.pink};
`